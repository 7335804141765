import React from "react"

import lnr from "../static/vendors/linericon/style.module.css"

export default () => (
	<section className="work_area p_120">
		<div className="container">
			<div className="main_title">
				<h2>Online Worksheets</h2>
				<p>
					Scratchwork makes it easy to create and distribute worksheets as well as collect completed assignments.
				</p>
			</div>
			<div className="work_inner row">
				<div className="col-lg-4">
					<div className="work_item">
						<i className={`${lnr.lnr} ${lnr.lnrPencil}`}></i>
						<i className={`${lnr.lnr} ${lnr.lnrMagicWand}`}></i>
						<a href="#">
							<h4>Create from anywhere</h4>
						</a>
						<p>
                            Scratchwork extracts drawings from photos, making ordinary paper and dry erase boards into editable worksheets.
                        </p>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="work_item">
						{/*<i className={`${lnr.lnr} ${lnr.lnrRocket}`}></i>*/}
						<i className={`${lnr.lnr} ${lnr.lnrRocket}`}></i>
						<a href="#">
							<h4>Distribute to individuals or groups</h4>
						</a>
						<p>
							Simply send students or groups a unique url to distribute your worksheet. Scratchwork
                            automatically creates a copy of your board for students to work on. 
						</p>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="work_item">
						<i className={`${lnr.lnr} ${lnr.lnrInbox}`}></i>
						<i className={`${lnr.lnr} ${lnr.lnrMagicWand}`}></i>
						<i className={`${lnr.lnr} ${lnr.lnrThumbsUp}`}></i>
						<i className={`${lnr.lnr} ${lnr.lnrGift}`}></i>
						<a href="#">
							<h4>Collect instantly</h4>
						</a>
						<p>
                            As an instructor, you always have access to students work even as they complete the assignment.
                            You can collect the work whenever you want.
                        </p>
					</div>
				</div>
			</div>
		</div>
	</section>
)
