import React from "react"

/*
	__Notes on imports__
	linearicon
		classnames prefixed by lnr-
		in ../static/vendors/linericon/style.module.css (manually renamed to style.module.css)
		[] can I avoid having all the fonts downloaded? (or can I replace with another icon set?)
	fontawesome
		classnames prefixed by fa-
		in ../static/vendors/font-awesome.min.module.css
*/

import "../static/vendors/animate-css/animate.css"
import "../static/vendors/flaticon/flaticon.css"

import routes from "../../../services/routes"

import Banner from "../banner"
//~ import Banner from "./0_banner"
import Values from "./1_values"
import Customers from "./2_customers"
import Testimonials from "./3_testimonials"
import Supporters from "./4_supporters"
import FinalPitch from "./5_finalpitch"
import Bottom from "./6_bottom"

export default () => (
	<>
		<Banner title="For Educators" link={routes.educators} />
		<Values />
		<FinalPitch />
		<Bottom />
	</>
);
//~ export default () => (
	//~ <>
		//~ <Banner title="For Educators" link={routes.educators} />
		//~ <Values />
		//~ <Customers />
		//~ <Testimonials />
		//~ <Supporters />
		//~ <FinalPitch />
		//~ <Bottom />
	//~ </>
//~ );
