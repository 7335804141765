import React from "react"

import lnr from "../static/vendors/linericon/style.module.css"

export default ({reason}) => {
	// TODO consider using input onFocus and onBlur to remove placeholder
	return (
		<form className="contactme-form">
			<input type="hidden" name="reason" value={reason} />
			<input
				type="email"
				name="email"
				placeholder="Enter email address"
				style={{height:"40px", width:"200px", verticalAlign:"middle", border:"none", padding:"0 0 0 20px"}}
				required
			>
			</input>
			<button
				className="btn sub-btn"
				style={{height:"40px", verticalAlign:"middle", background:"black", color:"white", borderRadius:"0px", margin:0}}
			>
				<span className={`${lnr.lnr} ${lnr.lnrArrowRight}`}></span>
			</button>
		</form>
	);
}