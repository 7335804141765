import React from "react"

import fa from "../static/vendors/fontawesome/fontawesome.module.css"
import css from "./6_bottom.module.css"
import { Link } from "../../../services/routes" // "gatsby"
import routes from "../../../services/routes"

export default () => (
	<footer className={`${css.footer}`}>

		<div className="container" style={{textAlign:"center"}}>
			<div className="row">
				<p style={{margin:"0 auto"}}>
					<Link to={routes.contact} style={{marginRight:"20px"}}>
						Contact Us{' '}
					</Link>
					<Link to={routes.privacy_policy} style={{marginRight:"20px"}}>
						Privacy Policy{' '}
					</Link>
					<Link to={routes.terms_of_service}>Terms of Service</Link>
				</p>
			</div>
			<div className="row">
				<div className="col-lg-12" style={{marginTop:"40px", textAlign:"center"}}>
					<p>
						<span>© </span>
						<span>{new Date().getFullYear()}</span> All rights reserved | This template is made with{' '}
						<i className={`${fa.fa} ${fa.faHeartO}`} aria-hidden="true"></i> by{' '}
						<a href="https://colorlib.com" target="_blank">
							Colorlib
						</a>
					</p>
				</div>
			</div>
		</div>
	</footer>
)
